
.title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 30px;
}

@media (max-width: 450px) { 
    .title {
        flex-direction: column;
    }
}    

  
  a.name {
    text-decoration: none;
    text-align: center;
    color: black;
    font-size: 50px;
    padding: 20px 20px 20px 25px;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
    font-weight: 300;
  }
  
.logo-right {
  font-size: 60px;
  padding-top: 10px
}