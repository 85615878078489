
a.crt-logo {
    display: none !important;
  }
  
  span.crt-post-username  {
    padding: 0px 5px 7px 5px !important;
  }
  
  span.crt-post-username > a{
    font-family: inherit !important;
    font-size: 12px;
  }
  
  a.crt-post-date-link {
    font-family: inherit !important;
  }
  
  div.crt-post-share {
    display: none;
  }
  
  div.crt-post-name >span {
    display: none;
  }
  
  div.crt-post-name >br {
    display: none;
  }
  
  p.crt-post-date {
    display: flex;
    justify-content: center;
  }
  
  div.crt-popup-footer {
    display: none;
    height: 0 !important;
  }

  span.crt-social-icon > svg {
      color: white !important;
  }
  
div.crt-post-content-image:hover {
    cursor: pointer;
}